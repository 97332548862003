import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Contact = () => (
  <Layout>
    <SEO title="Contact Colin" />
    <div className="article max-w-lg">
      <h1 className="text-4xl">Contact Colin</h1>
      <div className="max-w-md">
        <p>You message has been submitted successfully.</p>
      </div>
    </div>
  </Layout>
)

export default Contact
